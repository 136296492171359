import React, { useEffect } from "react"
import Layout from "../components/Layout"
import { navigate } from "gatsby"

const NotFoundPage = () => {
  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/404")
    }
  }, [])

  return (
    <Layout>
      <h1>404: Not Found</h1>
      <p>Diese Seite existiert nicht.</p>
    </Layout>
  )
}

export default NotFoundPage
